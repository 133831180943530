/* eslint-disable */
import { createRouter, createWebHashHistory } from "vue-router"
const routes = [
    {
        path: '/',
        name: 'year',
        component: () => import('@/components/year'),
        meta: {title: '年终报告'},
    },
]

export const router = createRouter({
    history: createWebHashHistory(),
    routes: routes
})
export default router